import React, {useState, useRef, useEffect} from 'react'
import useScript from 'react-script-hook';
import paymentStyles from "../../styles/checkout/payment.module.scss"


const PayPalSmartButtons = ({handlePaypalSubmitOrder, data_}) => {
    const [loading, error_] = useScript({ src: `https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CLIENT_ID}&currency=EUR&locale=it_IT&disable-funding=sofort,venmo,mybank&components=buttons,messages&enable-funding=paylater`, checkForExisting: true});
    const [paid, setPaid] = useState(false);
    const [error1, setError1] = useState(null);
    const paypalRef = useRef(null);
    const paypalMsgRef = useRef(null);

    useEffect(() => {
      if (window.myButton) window.myButton.close();

      window.myMessage = !loading && window.paypal &&
      window.paypal.Messages({
        amount: data_.totale_da_pagare.toString(),
          style: {
            layout: "text",
            logo: {
              type: "inline",
            },
          },
      })
      window.myMessage && window.myMessage.render(paypalMsgRef.current)

        window.myButton = !loading && window.paypal &&
        window.paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                payer: {
                  name: {
                    given_name: data_.contatti_cliente.nome,
                    surname: data_.contatti_cliente.cognome
                  },
                  address: {
                      address_line_1: data_.indirizzo_fatturazione.indirizzo,
                      address_line_2: '',
                      admin_area_2: data_.indirizzo_fatturazione.comune,
                      admin_area_1: data_.indirizzo_fatturazione.provincia,
                      postal_code: data_.indirizzo_fatturazione.cap,
                      country_code: 'IT'
                  },
                  email_address: data_.contatti_cliente.email,
                  phone: {
                    phone_number: {
                      national_number: `39${data_.contatti_cliente.telefono}`
                    }
                  }
                },
                purchase_units: [{
                  description: `${new Date(Date.now())} - ${data_.contatti_cliente.nome} ${data_.contatti_cliente.cognome}`,
                  amount: {
                      value: data_.totale_da_pagare.toString(),
                      currency_code: 'EUR',
                      breakdown: {
                        item_total: {value: data_.breakdown.toString() , currency_code: 'EUR'},
                        discount: {value: data_.discount, currency_code: 'EUR'}
                      }
                  },
                  items: data_.prodotti
                 }], 
                 application_context: {
                  shipping_preference: 'NO_SHIPPING'
                }
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture()
              setPaid(true);
              handlePaypalSubmitOrder()
              return
            },
            onError: (err) => {
               setError1(err)
              return 
            },
          })
          window.myButton && window.myButton.render(paypalRef.current)
    });


    if (error1) {
            return  <p className={paymentStyles.alertRicevuta}>Siamo spiacenti, si è verificato un errore e il tuo pagamento con Paypal non è stato completato... verifica i dati che hai inserito, ricarica la pagina e riprova, se il problema persiste contattaci direttamente su whatsapp o via email</p>
    }

    if (paid) {
       return <p className={paymentStyles.alertRicevuta}>Un attimo di pazienza, stiamo processando il tuo ordine...</p>
    } 

    return ( <>
         <div  ref={paypalMsgRef} ></div>
          <div className={paymentStyles.paypal} ref={paypalRef} ></div>
          </>
    )
  }

export default PayPalSmartButtons
