
const emailRegex = /^\S+@\S+\.\S+$/;
var cfRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;


export const validate = (committedValues, errors) => {
    if (!committedValues.email) {
      errors.email = 'inserisci la tua email';
    } else if (!emailRegex.test(committedValues.email)) {
      errors.email = `email non valida`;
    }
    if (!committedValues.email_buono_regalo) {
      errors.email_buono_regalo = `inserisci l'email`;
    } else if (!emailRegex.test(committedValues.email_buono_regalo)) {
      errors.email_buono_regalo = `email non valida`;
    }
    if (!committedValues.nome) {
      errors.nome = 'inserisci il tuo nome';
    }
    if (!committedValues.cognome) {
        errors.cognome = 'inserisci il tuo cognome';
    }
    if (!committedValues.codice_fiscale) {
      errors.codice_fiscale = 'inserisci il tuo codice fiscale';
    } else if (!cfRegex.test(committedValues.codice_fiscale)) {
      errors.codice_fiscale = `codice fiscale non valido`;
    }
    if (!committedValues.indirizzo) {
      errors.indirizzo = 'inserisci il tuo indirizzo';
    }
    if (!committedValues.comune) {
      errors.comune = 'inserisci il tuo comune';
    }
    if (!committedValues.cap) {
      errors.cap = 'inserisci il tuo CAP';
    } else if (!/^\d+$/.test(committedValues.cap) || committedValues.cap.length !== 5) {
      errors.cap = 'CAP non valido';
    }
    if (!committedValues.provincia) {
      errors.provincia = 'inserisci la tua provincia';
    }
    if (!committedValues.telefono) {
      errors.telefono = 'inserisci il tuo numero di telefono';
    } else {  errors.telefono = ''; }

    if (!Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti'))){
    errors.summary = 'verifica i dati inseriti'} else {errors.summary = ''}

    return errors;
  };
