import React, {useContext, useState} from "react"
import useForm from "../hooks/useForm";
import formStyles from "../../styles/checkout/form.module.scss"
import PhoneInput from 'react-phone-input-2'
import it from 'react-phone-input-2/lang/it.json'
import 'react-phone-input-2/lib/style.css'
import {validate} from "../hooks/validateForms"
import { StoreContext } from "../../context/storeContext"


  const Form = ({setIsBtnVisible, cssClass}) => {
    const {committedValues, setCommittedValues, errors} = useContext(StoreContext)
    const [status, setStatus] = useState({})
    const {
      handleChangeCommitted,
      handleSubmit,
    } = useForm(validate);

    const handleSubmitUser = (e) => {
      setStatus({})
      handleSubmit(e)
      errors && Object.values(errors).every(x => (x === null || x === '' || x==='verifica i dati inseriti')) ? setIsBtnVisible(true) : setIsBtnVisible(false)
    }

    const province = [
        '',
        'Agrigento,AG',
        'Alessandria,AL',
        'Ancona,AN',
        'Aosta,AO',
        'Arezzo,AR',
        'Ascoli Piceno,AP',
        'Asti,AT',
        'Avellino,AV',
        'Bari,BA',
        'Barletta-Andria-Trani,BT',
        'Belluno,BL',
        'Benevento,BN',
        'Bergamo,BG',
        'Biella,BI',
        'Bologna,BO',
        'Bolzano,BZ',
        'Brescia,BS',
        'Brindisi,BR',
        'Cagliari,CA',
        'Caltanissetta,CL',
        'Campobasso,CB',
        'Carbonia-Iglesias,CI',
        'Caserta,CE',
        'Catania,CT',
        'Catanzaro,CZ',
        'Chieti,CH',
        'Como,CO',
        'Cosenza,CS',
        'Cremona,CR',
        'Crotone,KR',
        'Cuneo,CN',
        'Enna,EN',
        'Fermo,FM',
        'Ferrara,FE',
        'Firenze,FI',
        'Foggia,FG',
        'Forlì-Cesena,FC',
        'Frosinone,FR',
        'Genova,GE',
        'Gorizia,GO',
        'Grosseto,GR',
        'Imperia,IM',
        'Isernia,IS',
        'La Spezia,SP',
        "L'Aquila,AQ",
        'Latina,LT',
        'Lecce,LE',
        'Lecco,LC',
        'Livorno,LI',
        'Lodi,LO',
        'Lucca,LU',
        'Macerata,MC',
        'Mantova,MN',
        'Massa-Carrara,MS',
        'Matera,MT',
        'Medio Campidano,VS',
        'Messina,ME',
        'Milano,MI',
        'Modena,MO',
        'Monza e della Brianza,MB',
        'Napoli,NA',
        'Novara,NO',
        'Nuoro,NU',
        'Olbia-Tempio,OT',
        'Oristano,OR',
        'Padova,PD',
        'Palermo,PA',
        'Parma,PR',
        'Pavia,PV',
        'Perugia,PG',
        'Pesaro e Urbino,PU',
        'Pescara,PE',
        'Piacenza,PC',
        'Pisa,PI',
        'Pistoia,PT',
        'Pordenone,PN',
        'Potenza,PZ',
        'Prato,PO',
        'Ragusa,RG',
        'Ravenna,RA',
        'Reggio Calabria,RC',
        'Reggio Emilia,RE',
        'Rieti,RI',
        'Rimini,RN',
        'Roma,RM',
        'Rovigo,RO',
        'Salerno,SA',
        'Sassari,SS',
        'Savona,SV',
        'Siena,SI',
        'Siracusa,SR',
        'Sondrio,SO',
        'Taranto,TA',
        'Teramo,TE',
        'Terni,TR',
        'Torino,TO',
        'Ogliastra,OG',
        'Trapani,TP',
        'Trento,TN',
        'Treviso,TV',
        'Trieste,TS',
        'Udine,UD',
        'Varese,VA',
        'Venezia,VE',
        'Verbano-Cusio-Ossola,VB',
        'Vercelli,VC',
        'Verona,VR',
        'Vibo Valentia,VV',
        'Vicenza,VI',
        'Viterbo,VT'       
        ]

    const formInput = (input, errors, input_type='text') => (
        <div className={formStyles.formInput}>
            <label htmlFor={input}>{input.replace(/_/g, ' ')}</label>
            <input autoComplete="off" type={input_type} name={input} onChange={handleChangeCommitted} value={committedValues[input] || ''} required />
            {errors[input] && <p className={formStyles.formError}>{errors[input]}</p>}         
        </div>
    )

    const formSelect = (input, list, errors) => (
      <div className={formStyles.formInput}>
        <label htmlFor={input}>{input}</label>
        <select name={input} onChange={handleChangeCommitted} value={committedValues[input] || ''} required >
            {list.map((item,id) => (<option key={id} value={item}>{item}</option>))}
        </select>
        {errors[input] && errors[input] !== '' && <p className={formStyles.formError}>{errors[input]}</p>}       
    </div>
    )

    const formCheckbox = (input, label, isChecked, id) => 
  (
      <div className={`${formStyles.formInput} ${formStyles.reverse}`}>
        <label htmlFor={input}>{label}</label>
        <div className={formStyles.checkbox}>
          <input
            name={input}
            type="checkbox"
            onChange={handleChangeCommitted}
            defaultChecked={isChecked}
            id={id}
          />  
          <label htmlFor={id}></label>
        </div>
    </div>)



    return (
            <div className={`${formStyles.formBox} ${cssClass}`}>
              <form className={formStyles.form} onSubmit={handleSubmitUser} noValidate>
              <h4 className={formStyles.label}>i tuoi contatti</h4>
                {formInput('nome', errors)}
                {formInput('cognome', errors)}
                {formInput('email', errors, 'email')} 
                <div className={formStyles.formInputPhone}>
                 <label htmlFor='telefono'>telefono </label> 
                    <PhoneInput
                    country={'it'}
                    value={committedValues['telefono'] || null}
                    onChange={value => setCommittedValues({ ...committedValues, 'telefono': value})}
                    inputProps={{
                        name: 'telefono',
                        required: true,
                        placeholder: ''
                    }}
                    onlyCountries={['it']}
                    disableDropdown={true}
                    disableCountryCode={true}
                    buttonStyle={{backgroundColor: "#fff"}}
                    localization={it}
                    />
                    {errors['telefono'] && <p className={formStyles.formError}>{errors['telefono']}</p>}  
        
                </div>
                <h4 className={formStyles.label}>dati di fatturazione</h4>
                {formInput('codice_fiscale', errors)}
                {formInput('indirizzo', errors)}
                {formInput('comune', errors)}
                {formInput('cap', errors)}
                {formSelect('provincia', province, errors)} 
                <h4 className={formStyles.label}>a chi vuoi inviare il buono regalo?</h4>
                {formInput('email_buono_regalo', errors, 'email')} 
                <input type="hidden" value="1" name="embed" /> 
                <button type="submit" className={formStyles.btn}>Conferma i tuoi dati</button>
                {errors['summary'] && <p className={formStyles.errorSummary}>{errors['summary']}</p>} 
              </form>
            </div>
        
    );
  };
  
  export default Form