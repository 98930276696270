export const isDateEarlier = (d1, d2) => {
    let parts =d1.split('/');
    const date1 = Number(parts[2] + parts[1] + parts[0]);
    parts = d2.split('/');
    const date2 = Number(parts[2] + parts[1] + parts[0]);
    return date1 <= date2;
}

export const formatDate = (d) => {
    const parts = d.split('-');
    const date = new Date(parts[0], parts[1] - 1, parts[2]); 
    return date.toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
}