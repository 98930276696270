import React, { useState } from "react"
import orderSummaryStyles from "../../styles/checkout/order-summary.module.scss"
import formStyles from "../../styles/checkout/form.module.scss"
import {formatPrice} from "../../utilities/formatPrice"
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {isDateEarlier, formatDate} from "../../utilities/dateUtils"

const OrderSummary = ({promoCodes, setPromoCodes, promozioni, isBtnVisible, setIsVisible, setIsPromo, isPromo, cssClass, data, isCapWhitelisted}) => {
    //promo-avvento
/*     let today_day = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(0,2)
    today_day = parseInt(today_day).toString()
    const today_month = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(3,5)   
    const codiciPromo = (today_month === '12') && promozioni.map(promo => (promo.data.slice(0,2).trim() === today_day) && (promo.codice.trim()))
    const trattamentiPromo = (today_month === '12') && promozioni.map(promo => (promo.data.slice(0,2).trim() === today_day) && (promo.trattamento.trim()))
    const found = data.trattamenti && data.trattamenti.map(trattamento => trattamentiPromo && trattamentiPromo.includes(trattamento.nome.trim())).includes(true)
    */ 
    //promo
    const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const dailyPromoObj = promozioni.filter(promo =>  (isDateEarlier(formatDate(promo.inizio), today) && isDateEarlier(today, formatDate(promo.fine)) && (promo.codice.trim())))
    const activePromos = dailyPromoObj && dailyPromoObj.map(({fine, inizio, sconto, ...obj}) => {
      const trattamentiUnderPromoList = obj['trattamenti'].map(el => el.nome)
      return {...obj, 
      'trattamentiInCartUnderPromo': data.trattamenti && data.trattamenti.filter(trattamento => trattamentiUnderPromoList && trattamentiUnderPromoList.includes(trattamento.nome.trim()))
    }})
    
    const areThereActivePromos = activePromos.length !== 0 
    const trattamentiInCartUnderPromoCount = activePromos.map(obj => obj.trattamentiInCartUnderPromo.length).reduce((a, b) => a + b, 0)

    const [promoCode, setPromoCode] = useState('')
    const [error, setError] = useState('')
    const [promoError, setPromoError] = useState('')
    const [value, setValue] = useState(false)
    const info = `per proseguire torna indietro e conferma i tuoi dati di contatto e di fatturazione`
    const emailRegExp = /^\S+@\S+\.\S+$/;

    const handleChangeCheckbox = (event) => {
      event.persist();
      setValue(event.target.checked);
    }

    const handleChangePromo = (event) => {
      event.persist();
      setPromoCode(event.target.value);
      setPromoError('')
    }

    const handleSubmitPromo = (event) => {
      event.preventDefault();
      const activePromo = activePromos.filter(activePromoObj => activePromoObj.codice === promoCode.toUpperCase())
      const isThisPromoActive = activePromo.length === 1
      if (!areThereActivePromos || !isThisPromoActive) {
        setPromoError('Codice promo non valido')
        return
      } 
       if (activePromo && activePromo[0].trattamentiInCartUnderPromo.length === 0) {
        setPromoError('Promo non applicabile ai pezzi scelti') 
        return
      }
      setPromoError('') 
      setIsPromo(true)
      !promoCodes.includes(promoCode.trim().toUpperCase()) && setPromoCodes([...promoCodes, promoCode.trim().toUpperCase()])
    }

/*     const handleSubmitPromo = (event) => {
      event.preventDefault();
      if (!codiciPromo || codiciPromo && !codiciPromo.includes(promoCode.toUpperCase())) {
        setPromoError('Codice promo non valido')
        setIsPromo(false)
      } else {
        !found && setPromoError('Promo non applicabile ai trattamenti scelti')
        found && setPromoError('')
        setIsPromo(true)
      }
    } */
    
    const formInputPromo = (input="promo", promoError, inputType='text') => (
      <>
      <h4 className={orderSummaryStyles.label}>Hai un codice promo?</h4>
      <form className={orderSummaryStyles.formPromo} onSubmit={handleSubmitPromo} noValidate>
        <div className={orderSummaryStyles.formInputPromo}>
          <label htmlFor={input}></label>
          <input autoComplete="off" type={inputType} name={input} onChange={handleChangePromo} value={promoCode || ''} required />
          {promoError && <p className={formStyles.formErrorPromo}>{promoError}</p>}   
        </div>
        <button type="submit" className={formStyles.btn}>Applica lo sconto</button>
      </form>
      </>
  )

    const formCheckboxPay = (input, isChecked, id) => 
    (
        <div className={`${formStyles.formInput} ${formStyles.reverse}  ${orderSummaryStyles.checkbox}`}>
          <label htmlFor={input}>acconsento ai termini delle <Link to="/condizioni-di-vendita">condizioni di vendita</Link> e acconsento al trattamento dei miei dati personali in accordo con la <Link to="/privacy-policy">privacy policy</Link></label>
          <div className={formStyles.checkbox}>
            <input
              name={input}
              type="checkbox"
              onChange={handleChangeCheckbox}
              defaultChecked={isChecked}
              id={id}
            />  
            <label htmlFor={id}></label>
          </div> 
      </div>  
    )

     const isEmpty = (data) => {
      return (data.contatti_cliente.nome === "" ||  data.contatti_cliente.cognome === "" || data.contatti_cliente.email === "" || 
      data.contatti_cliente.telefono === "" ||  data.indirizzo_fatturazione.indirizzo === "" || data.indirizzo_fatturazione.comune === "" 
      || data.indirizzo_fatturazione.cap === "" || data.indirizzo_fatturazione.provincia === "" || data.email_buono_regalo === "")
    }

      const handlePreOrderSubmit = (event) => {
        event.preventDefault()
        setPromoError('')
        setPromoCode('')
        if (isEmpty(data)) {
          setError('torna indietro, verifica la completezza dei tuoi dati e confermali')
          return error
        }  
        if (data && data.contatti_cliente.email && !emailRegExp.test(data.contatti_cliente.email)) {
          setError('La tua email non è valida, verificala e confermala di nuovo')
          return error
        }
        if (data && data.trattamenti === 0 ) {
          setError('il tuo carrello è vuoto, ritorna allo shop per riempirlo!')
          return error
        }  
        if (value) {         
          setError('')
          setIsVisible(true)
        } else {setError('per proseguire occorre il tuo consenso alle condizioni di vendita e alla privacy')
          return error
      }
    }
    
    return (
        <div className={`${orderSummaryStyles.card} ${cssClass}`}>
            <div className={orderSummaryStyles.dataWrapper}>
            
                <h4 className={orderSummaryStyles.label}>i tuoi contatti</h4>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.nome} {data && data.contatti_cliente.cognome}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.email}</p>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.telefono}</p>

                <h4 className={orderSummaryStyles.label}>dati di fatturazione</h4>
                <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.codice_fiscale.toUpperCase()}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.indirizzo}</p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.comune} </p>
                <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.cap} {data && data.indirizzo_fatturazione.provincia && data.indirizzo_fatturazione.provincia.slice(-2)}</p>
                
                <h4 className={orderSummaryStyles.label}>email per l'invio del tuo buono regalo</h4>
                <p className={orderSummaryStyles.data}>{data && data.email_buono_regalo}</p>

                <h4 className={orderSummaryStyles.label}>i tuoi trattamenti</h4>
                {data && data.quantita_totale_trattamenti !== 1  && data.quantita_totale_trattamenti !==0 && <p className={orderSummaryStyles.dataLeftCount}>Hai {data && data.quantita_totale_trattamenti !== 1 && data.quantita_totale_trattamenti} trattamenti nel carrello</p>}
                {data && data.quantita_totale_trattamenti === 1  && <p className={orderSummaryStyles.dataLeft}>Hai {data.quantita_totale_trattamenti} trattamento nel carrello</p>}
                {data && data.trattamenti.length !== 0 && <ul className={orderSummaryStyles.list}>
                {data && data.trattamenti.map(product =>(<li key={product.id}>
                    <div  className={orderSummaryStyles.fotoBox}>
                    <Img
                      className={orderSummaryStyles.foto}
                      fluid={product.foto.childImageSharp.fluid}
                      alt={product.foto.alternativeText}
                    />
                  </div>
                    <div className={orderSummaryStyles.name}>{product.nome}</div> 
                    <div className={orderSummaryStyles.priceQtyWrapper}>
                      <div className={orderSummaryStyles.qty}>x {product.quantity}</div>
                      <div className={orderSummaryStyles.price}>{formatPrice(product.prezzo * product.quantity)}</div>
                    </div>
                  </li>))}
                </ul>}
                {isPromo && promoCodes.length > 0 && data.quantita_totale_trattamenti && (trattamentiInCartUnderPromoCount > 0) && areThereActivePromos && <p className={orderSummaryStyles.dataLeftPromo}>{`Codice promo ${promoCodes.join(' | ').toUpperCase()} applicato`}</p>}
                {data && data.quantita_totale_trattamenti !== 0 && <h3 className={orderSummaryStyles.dataLeftTotal}>{`Totale da pagare ${data && formatPrice(data.totale_da_pagare)}`}</h3>}
                {data && data.quantita_totale_trattamenti !== 0 && <p className={orderSummaryStyles.dataLeft}>Tutti i prezzi sono IVA esenti</p>}         
                {data && data.contatti_cliente.length !== 0 && formInputPromo('promo', promoError)}
                {!isBtnVisible && <p className={`${orderSummaryStyles.data} ${orderSummaryStyles.alert}`}><span>ⓘ </span>{info}</p>}
                {isBtnVisible && <form  className={orderSummaryStyles.form} onSubmit={handlePreOrderSubmit} noValidate>
                {formCheckboxPay("isOKToPay", false, "OKToPay")}
                <button type="submit" className={orderSummaryStyles.btn}>Conferma il tuo ordine</button>
                {error && <p className={orderSummaryStyles.error}>{error}</p>}
            </form>}
            </div>
        </div>
    )

}

export default OrderSummary

// && codiciPromo && codiciPromo.includes(promoCode.toUpperCase()) && found