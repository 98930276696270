import React, { useState } from "react"
import paymentStyles from "../../styles/checkout/payment.module.scss"
import PayPalSmartButtons from "./paypalSmartButtons"
import SatispayPayment from "./satispayPayment"


const Payment = ({promoCodes, isPromo, setOrder, isVisible, data, cssClass, clearCommittedCart, clearCommittedValues}) => {

    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState('')
    const emailRegExp = /^\S+@\S+\.\S+$/;

    const isEmpty = (data) => {
      return (data.contatti_cliente.nome === "" ||  data.contatti_cliente.cognome === "" || data.contatti_cliente.email === "" || 
      data.contatti_cliente.telefono === ""
       || data.indirizzo_fatturazione.indirizzo === "" || data.indirizzo_fatturazione.comune === "" 
      || data.indirizzo_fatturazione.cap === "" || data.indirizzo_fatturazione.provincia === "" )
    }

    const saveOrder = async (data) => {
      if (!isEmpty(data) && (data.contatti_cliente.email && emailRegExp.test(data.contatti_cliente.email))) {
          const response = await fetch(`${process.env.GATSBY_STRAPI_PATH}/ordinis`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          if (!response.ok) {
            return "fail"
          }
          const order = await response.json()
          return order
        } else {
        setError('Siamo spiacenti ma a causa di un errore del server il tuo ordine non è stato registrato nei nostri sistemi... verifica i dati che hai inserito, ricarica la pagina e riprova, se il problema persiste contattaci direttamente su whatsapp o via email')
        return error
      } 
    }

    const sendEmailMailer = async (order) => {
      const email_response = await fetch(`${process.env.GATSBY_NETLIFY_MAILER_URL}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order)
      })
      if (email_response.ok) {return "success"}
    }

    const sendEmailVoucher = async (order) => {
      const email_response = await fetch(`${process.env.GATSBY_NETLIFY_VOUCHER_URL}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order)
      })
      if (email_response.ok) {return "success"}
    }
    
    const handleSubmitOrder = async (event, method) => {
      if (method !== 'satispay') {
        event.preventDefault();   
        if (disabled) {
          return;
        }
        setDisabled(true)
      }
    }

    const handlePaypalSubmitOrder = async () => {
        data.promo_code = isPromo ? promoCodes.join(' | ').toUpperCase() : ''
        delete data.prodotti;
        delete data.breakdown;
        delete data.discount;
        const data_ricevuta = {...data};
        data.trattamenti = data.trattamenti.map(({ foto, ...item }) => item)

        const order = await saveOrder(data);
        if (order === 'fail') {
          setError(`Grazie per il tuo ordine, abbiamo ricevuto il tuo pagamento, ma a causa di un errore del server non ti abbiamo potuto inviare la nostra email di conferma. In sostituzione fai riferimento all'email che hai ricevuto da PayPal e se vuoi stampa pure questa pagina come ricevuta`);
          return error[0]
        } else {
        delete order._id;
        delete order.__v;
        delete order.createdAt;
        delete order.published_at;
        delete order.updatedAt;
        order.isPromo = isPromo ? "true" : "false"

        const result = await sendEmailMailer(order)
        const voucher = await sendEmailVoucher(order)

        if (result === "success" && voucher === "success") {
          data_ricevuta.id = order.id
          data_ricevuta.isPromo = isPromo ? "true" : "false"
          setOrder(data_ricevuta)
          clearCommittedCart()
          clearCommittedValues()          
          return data_ricevuta;
        } else { 
          setError(`Grazie per il tuo ordine numero ${order.id}, lo abbiamo registrato correttamente ma a causa di un errore del server non ti abbiamo potuto inviare la nostra email di conferma. In sostituzione fai riferimento all'email che hai ricevuto da PayPal e se vuoi stampa pure questa pagina come ricevuta.`)
          return error;
          }   
        }      
      }

    return (
      <div className={`${paymentStyles.card} ${cssClass}`}>
          {isVisible && <div className={paymentStyles.dataWrapper}>
            {!error && <PayPalSmartButtons 
                  handlePaypalSubmitOrder={handlePaypalSubmitOrder}    
                  data_={data}     
            />}
            {error && <p className={paymentStyles.alertRicevuta}>{error}</p>}
            {!error && <SatispayPayment 
                    handleSubmitOrder={handleSubmitOrder}    
                    data_={data}    
                    disabled={disabled}
            />}
          </div>}
     </div>   
    )
  }

export default Payment