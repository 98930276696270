import React, {useEffect} from "react"
import orderSummaryStyles from "../../styles/checkout/order-summary.module.scss"
import formStyles from "../../styles/checkout/form.module.scss"
import {formatPrice} from "../../utilities/formatPrice"
import Img from "gatsby-image"

const Ricevuta = ({data, cssClass}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <div className={`${orderSummaryStyles.cardRicevuta} ${cssClass}`}>
           
            <div className={orderSummaryStyles.dataWrapper}>
            <h4 className={formStyles.labelMain}>ricevuta n. {data.id}</h4>
            <h4 className={orderSummaryStyles.label}>i tuoi contatti</h4>
            <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.nome} {data && data.contatti_cliente.cognome}</p>
            <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.email}</p>
            <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.telefono}</p>

            <h4 className={orderSummaryStyles.label}>dati di fatturazione</h4>
            <p className={orderSummaryStyles.data}>{data && data.contatti_cliente.codice_fiscale.toUpperCase()}</p>
            <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.indirizzo}</p>
            <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.comune} </p>
            <p className={orderSummaryStyles.data}>{data && data.indirizzo_fatturazione.cap} {data && data.indirizzo_fatturazione.provincia && data.indirizzo_fatturazione.provincia.slice(-2)}</p>
            
            <h4 className={orderSummaryStyles.label}>email per l'invio del tuo buono regalo</h4>
            <p className={orderSummaryStyles.data}>{data && data.email_buono_regalo}</p>

            <h4 className={orderSummaryStyles.label}>i tuoi trattamenti</h4>
            {data && data.quantita_totale_trattamenti !== 1  && data.quantita_totale_trattamenti !==0 && <p className={orderSummaryStyles.dataLeftCount}>Hai {data && data.quantita_totale_trattamenti !== 1 && data.quantita_totale_trattamenti} trattamenti nel carrello</p>}
            {data && data.quantita_totale_trattamenti === 1  && <p className={orderSummaryStyles.dataLeft}>Hai {data.quantita_totale_trattamenti} trattamento nel carrello</p>}
            {data && data.trattamenti.length !== 0 && <ul className={orderSummaryStyles.list}>
            {data && data.trattamenti.map(product =>(<li key={product.strapiId}>
              <div  className={orderSummaryStyles.fotoBox}>
              <Img
                className={orderSummaryStyles.foto}
                fluid={product.foto.childImageSharp.fluid}
                alt={product.foto.alternativeText}
              />
            </div>
              <div className={orderSummaryStyles.name}>{product.nome}</div> 
              <div className={orderSummaryStyles.priceQtyWrapper}>
                <div className={orderSummaryStyles.qty}>x {product.quantity}</div>
                <div className={orderSummaryStyles.price}>{formatPrice(product.prezzo * product.quantity)}</div>
              </div>
              </li>))}
            </ul>}
            {data.promo_code && <p className={orderSummaryStyles.dataLeftPromo}>{`Codice promo ${data.promo_code} applicato`}</p>}
            {data && data.quantita_totale_trattamenti !== 0 && <h3 className={orderSummaryStyles.dataLeftTotal}>{`Totale da pagare ${data && formatPrice(data.totale_da_pagare)}`}</h3>}
            {data && data.quantita_totale_trattamenti !== 0 && <p className={orderSummaryStyles.dataLeft}>Tutti i prezzi sono IVA esenti</p>}         
            </div>
        </div>
    )

}

export default Ricevuta